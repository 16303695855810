import logo from './logo.png';
import './App.css';
import './mApp.css';
import { useMediaQuery } from 'react-responsive'

// TODO: change favicon
// TODO: mobile styling

function App() {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    if (isTabletOrMobile) {
      return <div className="mApp">
        <header className="mApp-header">
          <img src={logo} className="mApp-logo" alt="logo" />
          <p className="mmilktea">Milk Tea Protein Shake</p>
          <p className="mcomingsoon">Coming Soon</p>
          <div className = "memailcontainer">
            <form action="https://submit-form.com/he6YDXg7">
              <input className="memailinput" type="email" id="email" name="email" placeholder="Enter Email Address" required="" />
              <button type="submit" hidden></button>
            </form>
            <p className="memailsubtext1">Enter your email address to receive an <b>exclusive discount code</b>.</p>
            <p className="memailsubtext2">We'll email you when our shake launches.</p>
          </div>
        </header>
      </div>
    } else {
      return <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p className="milktea">Milk Tea Protein Shake</p>
          <p className="comingsoon">Coming Soon</p>
          <div className = "emailcontainer">
            <form action="https://submit-form.com/he6YDXg7">
              <input className="emailinput" type="email" id="email" name="email" placeholder="Enter Email Address" required="" />
              <button type="submit" hidden></button>
            </form>
            <p className="emailsubtext1">Enter your email address to receive an <b>exclusive discount code</b>.</p>
            <p className="emailsubtext2">We'll email you when our shake launches.</p>
          </div>
        </header>
      </div>
    }
}

export default App;
